import './App.css';
import TextToSpeech from './components/TextToSpeech';

function App() {
  return (
    <div className='App'>
      <img className='w-full h-screen' src='/bg.jpg' alt='bg' />
      <div className='w-full h-screen absolute top-0 left-0'>
        <div className='w-full h-screen flex flex-col items-center justify-between py-8'>
          <div className='flex-1 flex flex-col items-center justify-center'>
            <div className='px-4 py-2 rounded-xl flex items-center justify-center text-cyan-100 space-x-2 lg:space-x-4'>
              <span className='text-4xl lg:text-5xl xl:text-6xl 2xl:text-[7rem] font-bold'>
                Welcome !
              </span>
            </div>
            <h1 className='text-6xl lg:text-7xl xl:text-8xl 2xl:text-[10rem] text-gray-200 tracking-wider font-bold font-serif mt-12 text-center'>
              <TextToSpeech />
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
