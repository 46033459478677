import React, { useEffect, useState } from 'react';

const TextToSpeech = () => {
  const [fetchInterval, setFetchInterval] = useState(5000);
  const [text, setText] = useState('');
  const [speechText, setSpeechText] = useState('');

  useEffect(() => {
    const fetchText = () => {
      fetch('https://onera.gleeze.com/api/get-attend-user')
        .then((response) => response.json())
        .then((data) => {
          const newText = data.message;
          if (newText) {
            setText(newText);
            setSpeechText('Welcome! ' + newText);
            setFetchInterval(5000);
          } else {
            setFetchInterval(2000);
          }
        })
        .catch((err) => console.error('Failed to fetch text:', err));
    };

    const intervalId = setInterval(fetchText, fetchInterval);
    fetchText();
    return () => clearInterval(intervalId);
  }, [fetchInterval]);

  useEffect(() => {
    if (speechText) {
      speak(speechText);
    }
  }, [speechText]);

  useEffect(() => {
    if (text) {
      const timer = setTimeout(() => {
        setText('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [text]);

  const speak = (textToSpeak) => {
    const speechSynthesis = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(textToSpeak);
    speechSynthesis.speak(utterance);
  };

  return <div>{text}</div>;
};

export default TextToSpeech;
